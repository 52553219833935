
.content-left {
  .action-buttons {
    .actn-icon {
      font-size: 1.3rem !important;
    }

    .actn-text {
      font-size: 0.7rem !important;
      line-height: 0.8rem !important;
    }
  }

  .stream-container {
    width: 100%;
    //max-width: 1100px;

    .stream {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;

      .info-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px dashed grey;

        .info {
          flex: 0 0 400px;
          font-size: 2rem;
          line-height: 2.2rem;
          color: grey;
          font-weight: bold;
        }
      }
    }
  }


}
